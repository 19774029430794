import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import HeroMini from "../components/heroSmall"
import delvaimage from "../images/profile/delva.png"
import debraimage from "../images/profile/deb.png"
import zachimage from "../images/profile/zach.png"
import timimage from "../images/profile/tim.png"
import benimage from "../images/profile/ben.png"

// import People from '../images/people.jpg'
// import ThumbsUp from '../images/thumbup.jpg'
// import Fusion from "../images/f-logo.svg"
// import ContactUsMain from "../components/contactusmain"

const TheTeam = () => (
  <Layout>
    <Seo title="Meet the team" />
    <HeroMini title="Meet the team" />
    <main className="section has-text-black">
      <div className="container mb-6">
        <div className="columns">
          <div className="column is-half-desktop">
            <div className="card">
              <div className="card-content border-primary">
                <div className="media">
                  <div className="media-left">
                    <figure className="image is-48x48">
                      <img
                        src={zachimage}
                        alt=""
                      />
                    </figure>
                  </div>
                  <div className="media-content">
                    <p className="title is-4">Zachary Denholm</p>
                    <p className="subtitle is-6">ザッカリー</p>
                  </div>
                </div>

                <div className="content">
                  Meet Zach, Fusion founder, Senior Consultant and the solution
                  to your IT Technology and Development woes. As a teenager
                  growing up in the 90’s, Zach was big on computer-games, and
                  his obsession with technology has continued long into
                  adulthood. A founding member of Contract C5, which later
                  rebranded as the C5Alliance Group, Zach has played a
                  significant role in Jersey’s Digital growth and Development.
                  He has over twenty years of experience specialising in
                  Information Technology and Development, working with a host of
                  clients including The Jersey Financial Services Commission,
                  Sanne Group and Island Insurance, to name but a few. Most
                  recently, he has been supporting the States of Jersey Web
                  content platform and intranet using SharePoint and Microsoft
                  Azure. Passionate about User Experience and workflow
                  development, Zach will go to the greatest lengths to ensure
                  that your tech is working hard for you. . <br />
                </div>
              </div>
            </div>
          </div>

          <div className="column is-half-desktop">
            <div className="card">
              <div className="card-content">
                <div className="media">
                  <div className="media-left">
                    <figure className="image is-48x48">
                      <img
                        src={delvaimage}
                        alt=""
                      />
                    </figure>
                  </div>
                  <div className="media-content">
                    <p className="title is-4">Delva Guyoncourt</p>
                    <p className="subtitle is-6">デルバ</p>
                  </div>
                </div>

                <div className="content">
                  Delva is our resident problem-solving super-hero whose ability
                  to deliver clever, creative solutions for clients never fails
                  to amaze us. With over 10 years experience working in
                  Information Technology and Development, specialised in
                  ASP.NET, Office 365, SharePoint and User interface experience,
                  Delva has an impressive number of strings to her bow. She
                  brings to Fusion her experience working abroad as a front end
                  developer for some impressive organisations, including Henyep,
                  a Hong Kong based holding securities company and eForCity, the
                  Number One eBay seller in the U.S. An expert in Sharepoint and
                  UI experience development, and with significant experience in
                  Microsoft Office 365 and intranet and data security sharing
                  solutions, you can count on Delva to find the best solution
                  for you business. Since 2011 Delva has focused on developing
                  and supporting the States of Jersey web content and intranet
                  using SharePoint and Microsoft Azure, whilst also designing,
                  managing and supporting Sharepoint Intranet for private
                  companies. Unfazed by a tight deadline, Delva is our go-to
                  person for projects that need to be delivered quickly and
                  effectively.
                  <br />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="columns">
        <div className="column is-half-desktop">
            <div className="card">
              <div className="card-content">
                <div className="media">
                  <div className="media-left">
                    <figure className="image is-48x48">
                      <img
                        src={timimage}
                        alt=""
                      />
                    </figure>
                  </div>
                  <div className="media-content">
                    <p className="title is-4">Tim Rogers</p>
                    <p className="subtitle is-6">ティム</p>
                  </div>
                </div>

                <div className="content">
                  Tim uses his positive and pragmatic approach to help companies
                  deal with complex change, particularly in cases where there is
                  a strong political or cultural change element. A Commonwealth
                  Games Triathlete and World Coastal Championships Rower (GB
                  Champion in 2017) there aren’t many challenges that Tim isn’t
                  willing to take on! He has worked across the finance,
                  commercial, retail and public sectors, using his specialist
                  skills in goal setting, performance management and achievement
                  to transform businesses and deliver lasting change. Tim has
                  recently worked on a number of high-profile projects including
                  the incorporation of the Post Office, Harbours and Airport, as
                  well as a review of the Health and Social Services. Alongside
                  his 20 years experience in business, Tim has amassed an
                  impressive array of qualifications. With a Management
                  Consultancy MBA and other qualifications in Funds, Banking,
                  Compliance, as well as Change, Lean, DevOps, you can rest
                  assured that Tim most definitely knows his stuff ;) <br />
                </div>
              </div>
            </div>
          </div>

          <div className="column is-half-desktop">
            <div className="card">
              <div className="card-content">
                <div className="media">
                  <div className="media-left">
                    <figure className="image is-48x48">
                      <img
                        src={debraimage}
                        alt=""
                      />
                    </figure>
                  </div>
                  <div className="media-content">
                    <p className="title is-4">Debra Denholm</p>
                    <p className="subtitle is-6">デブラ</p>
                  </div>
                </div>

                <div className="content">
                  Debra is our Project Manager Extraordinaire, keeping the
                  Fusion team on track and bringing her characteristic energy
                  and enthusiasm to every project. She is a true agent for
                  change, capable of delivering large complex change programmes
                  in accordance with the most suitable methodologies. Debra is
                  all about helping organisations upskill heir teams, streamline
                  workflows and bring more efficiency to everyday tasks. A South
                  African Native, Debra has now called Jersey home for over 13
                  years. Before coming on board at Fusion, she gathered a wealth
                  of hands-on experience in governance and finance, including
                  positions at Capital Management, Ports of Jersey, Trufruti
                  Holdings and Ports of Jersey. She is a qualified Project
                  Manager, GDPR Practitioner and a Certified Accounts
                  Technician. <br />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="columns">
  
        <div className="column is-half-desktop">
            <div className="card">
              <div className="card-content">
                <div className="media">
                  <div className="media-left">
                    <figure className="image is-48x48">
                      <img
                        src={benimage}
                        alt=""
                      />
                    </figure>
                  </div>
                  <div className="media-content">
                    <p className="title is-4">Benjamin Denholm</p>
                    <p className="subtitle is-6">ベンジャミン</p>
                  </div>
                </div>

                <div className="content">
                <p>Zach’s younger brother and the newest member of the Fusion team, Ben is relatively new to software development, but learning from the best ;) He is a User Interface Developer specialising in website and app development in React which is a Javascript framework. Like Zach, Ben is a gamer at heart. He loves all things Japan and spent 5 years teaching English there, also learning Japanese during that time. Previously employed in document management at RBC, Ben understands how important it is for organisations to have efficient systems in place. Ben is excited about what his future holds  in the world of tech and development and looks forward to helping businesses achieve their goals. </p>
                </div>
              </div>
            </div>
          </div>

          <div className="column is-half-desktop">
            <div className="card">
              <div className="card-content">
                <div className="media">
                  <div className="media-left">
                    <figure className="image is-48x48">
                      <img
                        src={benimage}
                        alt=""
                      />
                    </figure>
                  </div>
                  <div className="media-content">
                    <p className="title is-4">Hayley Stoffberg</p>
                    <p className="subtitle is-6">ヘイリー</p>
                  </div>
                </div>

                <div className="content">
                  After 15 years of training a new generation of Astronauts at
                  NASA, Hayley spent 2 years at SpaceX before relocating to
                  Jersey. Just kidding ;) Hayley, our dedicated Training
                  Coordinator, has had years of hands-on experience in training
                  roles in her native South Africa and here in Jersey. She is
                  here to make sure our training and development programmes run
                  smoothly and efficiently, helping organisations equip their
                  teams with the necessary skills to help their business thrive.
                  Unlike the developers on the team (ahem, tech-geeks who like
                  to stay inside wherever possible), Hayley likes to get out
                  into the great outdoors and loves keeping fit with dance and
                  pilates.
                  <br />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  </Layout>
)

export default TheTeam
